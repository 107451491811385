<template>
  <section id="create-stmt-2">
    <div class="container">
      <div class="block-title">СОЗДАТЬ ЗАЯВКУ</div>
      <div class="form-block">
        <div class="row form-head">
          <div id="select-groups" class="col-sm-12">
            <a
              class="group-item btn btn-sm btn-secondary"
              :class="{ active: selectedItemKey === item.id }"
              @click="selectedItemKey = item.id"
              href="javascript://"
              v-for="item in productTypes"
              :key="item.id"
              >{{ item.name }}</a
            >
          </div>
          <div class="col">
            <hr />
          </div>
        </div>
        <div id="products-list">
          <div class="row">
            <div
              class="col-sm-12 col-md-6 mb-4"
              v-for="product in products"
              :key="product.id"
            >
              <b-card
                border-variant="primary"
                :header="product.name"
                header-bg-variant="primary"
                header-text-variant="white"
                class="h-100 shadow-sm"
              >
                <b-card-text>
                  <table class="m-0 table-bordered">
                    <tr>
                      <td>Ваш доход за выдачу</td>
                      <td>{{ product.income }} руб.</td>
                    </tr>
                    <tr>
                      <td>С нами уже заработали</td>
                      <td>{{ product.earned }} руб.</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="available-banks">
                        <a
                          href="javascript://"
                          v-b-modal.modal-product-partners
                          @click="selectProduct(product)"
                          >Список доступных банков</a
                        >
                      </td>
                    </tr>
                  </table>

                  <b-button
                    class="btn-success text-white"
                    v-b-modal.modal-form
                    @click="currentProduct = product"
                    >ПОДАТЬ ЗАЯВКУ</b-button
                  >
                </b-card-text>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductPartnersModal :product="product" />
    <FormModal :product="currentProduct" @reset-form="currentProduct = null" />
  </section>
</template>

<script>
import ProductPartnersModal from './ProductPartnersModal';
import FormModal from './FormModal';

export default {
  components: {
    ProductPartnersModal,
    FormModal,
  },
  data() {
    return {
      selectedItemKey: 1,
      productsByType: [],
      product: null,

      currentProduct: null,
    };
  },
  computed: {
    productTypes() {
      return this.productsByType.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },

    products() {
      let productsOfType = this.productsByType.find(
        (item) => item.id === this.selectedItemKey
      );
      return productsOfType &&
        productsOfType.products &&
        productsOfType.products.data
        ? productsOfType.products.data
        : [];
    },
  },
  mounted() {
    this.$axios
      .get('/api/v1/cashback/products/grouped-by-type')
      .then((response) => {
        if (response.data && response.data.data) {
          this.productsByType = response.data.data;
        }
      });
  },
  methods: {
    selectProduct(product) {
      this.product = product;
    },
  },
};
</script>

<style lang="scss">
#create-stmt-2 {
  @include down(1050) {
    .container {
      padding: 0 50px;
    }
  }
  @include down(670) {
    .container {
      padding: 0;
    }
  }
  .available-banks {
    text-align: center;

    a {
      border-bottom: 1px dashed #adadad;

      &:hover {
        text-decoration: none;
        border-bottom: 1px dashed #313131;
      }
    }
  }

  .container {
    max-width: 970px;
  }

  .block-title {
    font-weight: 500;
    font-size: 41.6023px;
    line-height: 47px;
    text-transform: uppercase;
    color: $blueDark;
    margin-top: 81px;
    margin-bottom: 70px;
    text-align: center;
  }

  .form-block {
    background: $whtie;
    padding: 47px 70px 38px 70px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 135px;

    .form-head {
      text-align: center;

      .head-top-line {
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        margin-bottom: 20px;

        a {
          color: $greyDark;

          &:hover {
            text-decoration: none;
          }

          &.active {
            color: $green-dark;
          }
        }
      }

      .head-bottom-line {
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
      }

      hr {
        width: 100%;
        height: 3px;
        background: $green-light;
        border-radius: 5px;
        margin: 24px 0;
      }

      #select-groups {
        .group-item {
          margin: 0 20px 15px 0;
          padding: 10px 15px;
          font-size: 15px;
          line-height: 17px;
          background: $greyLight;
          border: 1px solid rgba(185, 187, 190, 0.3);

          &:last-of-type {
            margin-right: 0;
          }

          &.active {
            background: $green-light;
          }
        }
      }
    }

    #products-list {
      .card {
        .card-body {
          padding: 0px;

          .card-text {
            font-size: 14px;
            height: 100%;
            display: flex;
            flex-flow: column;

            table {
              width: 100%;
              flex: 1;

              td {
                padding: 8px 10px;
              }
            }

            .btn {
              margin: 10px;
              // border-radius: 0;
            }
          }
        }
      }
    }
  }
}
</style>
