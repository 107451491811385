<template>
    <b-modal 
        id="modal-product-partners" 
        ref="modal-product-partners" 
        centered 
        title="Список доступных банков"
        :hide-footer="true"
        @show="getData"
        >
        <div class="container">
            <div v-if="loading" class="text-center">
                <b-spinner label="Загрузка..."></b-spinner>
            </div>
            <div v-else class="row product-partners-list p-3">
                <div v-for="partner, key in partners" :key="key" class="product-partner-item border-primary btn btn-outline-light p-2 text-body" v-b-modal.modal-partner-info @click="selectPartner(partner)">
                    <div class="product-partner-item__logo">
                        <img :src="partner.logo">
                    </div>
                    <div class="product-partner-item__name">{{ partner.name }}</div>
                </div>
            </div>
        </div>
        <PartnerInfoModal :partner="partner"/>
    </b-modal>
</template>

<script>
import PartnerInfoModal from './PartnerInfoModal';

export default {
    props: ['product'],
    components: {
        PartnerInfoModal
    },
    data() {
        return {
            loading: false,
            partners: [],
            partner: null,
        }
    },
    mounted() {
        
    },
    methods: {
        getData() {
            this.partners = this.product.partners.data;
        },

        selectPartner(partner) {
            this.partner = partner;
        }
    }
}
</script>

<style lang="scss">
#modal-product-partners {
    .product-partners-list {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);

        .product-partner-item {
            display: flex;
            align-items: center;
            cursor: pointer;

            &__logo {
                margin-right: 0.8em;

                img {
                    width: 40px;
                }
            }
        }
    }
}
</style>