<template>
    <div id="wrap">
        <Main/>
        <Steps/>
        <Products/>
        <Banks/>
        <Terms/>
        <Consultation/>
        <Footer/>
    </div>
</template>

<script>
import Footer from '../components/Footer';
import Main from '../components/blocks/Main';
import Steps from '../components/blocks/Steps';
import Products from '../components/cashback/Products';
import Banks from '../components/blocks/Banks';
import Terms from '../components/blocks/Terms';
import Consultation from '../components/blocks/Consultation';

export default {
    components: {
        Footer,
        Main,
        Products,
        Steps,
        Banks,
        Terms,
        Consultation
    }
}
</script>

<style lang="scss">
#wrap {
    background: rgba(230, 240, 255, 0.3);
}
</style>