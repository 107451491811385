<template>
  <div class="col-md-6">
    <b-form-group
      :label="field.title"
      :label-for="`form_${field.name}`"
      class="mb-3"
      :class="{
        'is-valid': isValid !== null && isValid,
        'is-invalid': isValid !== null && !isValid,
      }"
    >
      <input
        v-if="field.type === 'inn'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-mask="['############']"
        v-model="inputValue"
        :required="field.is_required"
      />
      <input
        v-if="field.type === 'snils'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-mask="['############']"
        v-model="inputValue"
        :required="field.is_required"
      />

      <input
        v-if="field.type === 'phone'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        v-mask="['+7 (###) ###-##-##']"
        :placeholder="field.title"
        v-model="inputValue"
        :required="field.is_required"
      />
      <input
        v-if="field.type === 'email'"
        class="input"
        type="email"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-model="inputValue"
        :required="field.is_required"
      />
      <input
        v-if="field.type === 'depart_code'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-model="inputValue"
        :required="field.is_required"
      />
      <input
        v-if="field.type === 'passport'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-model="inputValue"
        :required="field.is_required"
      />
      <input
        v-if="field.type === 'string'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-model="inputValue"
        :required="field.is_required"
      />
      <input
        v-if="field.type === 'integer'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-mask="['############']"
        v-model="inputValue"
        :required="field.is_required"
      />
      <money
        v-if="field.type === 'float'"
        class="input"
        type="text"
        :id="`form_${field.name}`"
        :name="field.name"
        :placeholder="field.title"
        v-bind="money"
        v-model="inputValue"
        :required="field.is_required"
      />
      <date-picker
        v-if="field.type === 'date'"
        format="YYYY-MM-DD"
        type="date"
        placeholder="Select date"
        v-model="inputValue"
        :required="field.is_required"
      />
      <span class="d-block invalid-feedback" v-if="inputError">{{
        inputError[0]
      }}</span>
    </b-form-group>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['field', 'isValid', 'inputError'],
  data() {
    return {
      inputValue: this.field.type === 'float' ? 0 : null,

      money: {
        decimal: ',',
        thousands: ' ',
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {},
  watch: {
    inputValue() {
      this.$emit('input-changed', {
        name: this.field.name,
        value: this.inputValue,
      });
    },
  },
};
</script>

<style lang="scss">
.form-group {
  &.is-invalid {
    input {
      border: 1px solid #dc3545;
    }
  }
  &.is-valid {
    input {
      border: 1px solid #28a745;
    }
  }
}
</style>
