<template>
  <b-modal
    id="modal-form"
    ref="modal-form"
    centered
    size="lg"
    title="Добавление новой заявки"
    @hidden="resetForm"
  >
    <div class="container">
      <div v-if="loading" class="text-center">
        <b-spinner label="Загрузка..."></b-spinner>
      </div>
      <div v-else>
        <form
          id="create-stmt-form"
          class="stmt-form"
          ref="form"
          action="javascript://"
          @submit.prevent="onSubmit"
        >
          <div class="row mb-3">
            <form-modal-field
              v-for="(field, idx) in currentFormFields"
              :key="idx"
              :field="field"
              :is-valid="inputValid(field.name)"
              :input-error="inputError(field.name)"
              @input-changed="addFormData"
            />
          </div>

          <div class="row mb-3">
            <div class="col-md-12">
              <b-form-group
                label="Комментарий"
                label-for="input-formatter"
                class="mb-3"
              >
                <textarea
                  class="input-style input"
                  type="text"
                  rows="3"
                  v-model="formComment"
                  placeholder="Комментарий"
                ></textarea>
              </b-form-group>
            </div>
          </div>
          <div class="stmt-form__title">
            <h5>Выберите банки</h5>
            <button
              class="border-primary btn btn-outline-light text-body"
              :class="{
                selected: selectedAllPartners,
              }"
              @click.prevent="selectedAll()"
            >
              <span v-if="selectedAllPartners">Отменить все</span>
              <span v-else>Выбрать все</span>
            </button>
          </div>
          <div
            class="row partners-list p-3"
            v-if="product"
            :class="{ 'partners-list--error': inputValid('comment') }"
          >
            <div
              v-for="(partner, key) in product.partners.data"
              :key="key"
              class="partners-list__item border-primary btn btn-outline-light p-2 text-body"
              :class="{
                'partners-list__item--selected': partnerSelected(partner.id),
              }"
              @click="togglePartner(partner)"
            >
              <div class="partners-list__item-logo">
                <img :src="partner.logo" />
              </div>
              <div class="partners-list__item-name">{{ partner.name }}</div>
            </div>
            <div
              class="d-block invalid-feedback"
              v-if="formError && selectedPartners.length === 0"
            >
              Выберите банк
            </div>
          </div>
        </form>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="secondary"
        class="float-right"
        @click="hideModal"
        :disabled="loadingOnlyBtn"
        >Отменить</b-button
      >
      <b-button
        variant="success"
        type="submit"
        :disabled="loadingOnlyBtn"
        form="create-stmt-form"
        class="form-submit-btn float-right text-white"
      >
        <span v-if="loadingOnlyBtn"
          ><b-spinner label="Загрузка..." /> Отправляем</span
        >
        <span v-else>Отправить заявку</span></b-button
      >
    </template>
  </b-modal>
</template>

<script>
import FormModalField from './FormModalField';
export default {
  components: { FormModalField },
  props: ['product'],

  data() {
    return {
      captchaLoading: false,
      token: null,

      formError: null,

      formInputs: {},
      formComment: '',
      selectedAllPartners: false,
      selectedPartners: [],
      currentFormFields: null,

      loading: true,
      loadingOnlyBtn: false,
    };
  },
  computed: {
    formErrorText() {
      if (!this.formError) return null;

      return Object.values(this.formError);
    },
  },
  methods: {
    selectedAll() {
      this.selectedAllPartners = !this.selectedAllPartners;

      this.selectedPartners = this.selectedAllPartners
        ? this.product.partners.data.map((item) => item.id)
        : [];
    },

    inputError(name) {
      if (!this.formError) return null;

      const inputErrors = Object.entries(this.formError).filter((item) =>
        item[0].includes(name)
      );

      if (inputErrors.length === 0) return null;

      return inputErrors[0][1];
    },

    // Проверка на валидность input
    inputValid(name) {
      if (!this.formError) return null;

      const invalidInputsName = Object.keys(this.formError).map((item) =>
        item.replace('fields[', '').replace(']', '')
      );

      return !invalidInputsName.includes(name);
    },

    // Проверка выбран ли партнер
    partnerSelected(id) {
      return this.selectedPartners.includes(id);
    },

    // Выбор партнера
    togglePartner(partner) {
      this.partnerSelected(partner.id)
        ? this.selectedPartners.splice(
            this.selectedPartners.indexOf(partner.id),
            1
          )
        : this.selectedPartners.push(partner.id);
    },

    // Добавление данных формы
    addFormData(e) {
      this.formInputs[e.name] = e.value;
    },

    // Закрыть модалку
    hideModal() {
      this.$refs['modal-form'].hide();
    },

    // Очистить форму
    resetForm() {
      this.currentFormFields = null;
      this.formInputs = {};
      this.selectedPartners = [];
      this.formComment = '';
      this.formError = null;
      this.$emit('reset-form');
    },

    // Отправка заявки
    onSubmit() {
      this.loadingOnlyBtn = true;
      const data = {
        product_id: this.product.id,
        fields: this.formInputs,
        partners: this.selectedPartners,
        comment: this.formComment,
      };

      this.formError = null;

      this.$axios
        .post('/api/v1/cashback/leads', data)
        .then((response) => {
          if (
            response.data.success &&
            typeof response.data.data !== 'undefined'
          ) {
            this.resetForm();
            this.hideModal();
            this.$swal('Заявка отправлена!', '', 'success');
          } else {
            this.formError = response.error;

            //throw response.data.error;
          }
        })
        .catch((error) => {
          this.formError = null;
          console.log(error.response.data);

          if (error.response.data) this.formError = error.response.data.errors;
        })
        .finally(() => {
          this.loadingOnlyBtn = false;
          if (this.$refs.captcha) {
            this.$refs.captcha.refresh();
          }
        });
    },

    // Получить поля формы
    async getFormFields() {
      this.loading = true;
      if (!this.product) return;

      const result = await this.$axios.get(
        `api/v1/cashback/products/${this.product.id}/fields`
      );
      this.loading = false;

      this.currentFormFields = result.data.data;
    },
  },
  watch: {
    product() {
      this.getFormFields();
    },
  },
};
</script>

<style lang="scss">
.modal-body {
  padding: 2rem;
}
.form-submit-btn {
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}
.stmt-form {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  button {
    justify-content: center;
    font-size: 0.8rem;
    padding: 0.175rem 0.75rem;
    min-width: 120px;

    &.selected {
      background: #61ca31;
      border-color: #4ea326 !important;

      span {
        color: #fff;
      }
    }
  }
}
.partners-list {
  & &__item {
    display: flex;
    justify-content: space-between;
    width: 160px;
    margin: 0 15px 15px 15px;
    background: #fff;

    &--selected {
      border-color: #61ca31 !important;
    }

    &-logo {
      width: 40px;
      margin-right: 10px;

      img {
        max-width: 100%;
      }
    }
    &-name {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
