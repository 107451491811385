<template>
    <section id="banks">
        <div class="container">
            <ul>
                <li v-for="bank, key in banks" :key="key" class="bank-item">{{ bank.name }}</li>
            </ul>
        </div>
    </section>
</template>

<script>
import banks from '@/banks';

export default {
    data() {
        return {
            banks: banks.filter(item => !item.hidden)
        }
    }
}
</script>

<style lang="scss">
#banks {
    background: $green;
    padding: 0 100px;

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 0;

        .bank-item {
            font-weight: 500;
            font-size: 22.5px;
            line-height: 26px;
            color: $whtie;
        }
    }
}
</style>