export default [
    {
        hidden: false,
        key: 'alfa',
        name: 'Альфа-Банк',
        logo: require(`@/assets/images/banks/alfa.png`),
        summ: 'до 10 млн. руб',
        term: 'до 36 месяцев',
        rate: 'от 13,95 %',
        link: 'http://alfa.link/_hrs9A'
    },
    {
        hidden: true,
        key: 'sovcombank',
        name: 'Совкомбанк',
        logo: require(`@/assets/images/banks/sovcombank.png`),
        summ: 'до 100 млн. руб',
        term: 'до 12 месяцев',
        rate: '000 %'
    },
    {
        hidden: true,
        key: 'sberbank',
        name: 'Сбербанк',
        logo: require(`@/assets/images/banks/sberbank.png`),
        summ: 'до 100 млн. руб',
        term: 'до 12 месяцев',
        rate: '000 %'
    },
    {
        hidden: true,
        key: 'vtb',
        name: 'ВТБ',
        logo: require(`@/assets/images/banks/vtb.png`),
        summ: 'до 100 млн. руб',
        term: 'до 12 месяцев',
        rate: '000 %'
    },
    {
        hidden: true,
        key: 'simpleFinance',
        name: 'СимплФинанс',
        logo: require(`@/assets/images/banks/simpleFinance.png`),
        summ: 'до 150 млн. руб',
        term: 'до 10 месяцев',
        rate: 'от 9,9 %'
    },
    {
        hidden: true,
        key: 'papaFinance',
        name: 'Папа Финанс',
        logo: require(`@/assets/images/banks/papaFinance.png`),
        summ: 'до 3 млн. руб',
        term: 'до 12 месяцев',
        rate: 'от 15,5 %'
    },
    {
        hidden: false,
        key: 'psb',
        name: 'ПСБ',
        logo: require(`@/assets/images/banks/psb.png`),
        summ: 'до 10 млн. руб',
        term: 'до 24 месяцев',
        rate: 'от 11,9 %',
        link: 'https://client.lk.psbank.ru/?utm_source=rko&utm_medium=referral&utm_content=E8bqPQARQ-AThCnrGn3tsrwgPU0LIjJY'
    }
];