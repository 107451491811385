<template>
    <b-modal 
        id="modal-partner-info" 
        ref="modal-partner-info" 
        centered 
        :title="partnerData ? partnerData.name : ''"
        :hide-footer="true"
        :hide-header="!partnerData"
        @show="getData"
        >
        <div class="container">
            <div v-if="loading" class="text-center">
                <b-spinner label="Загрузка..."></b-spinner>
            </div>
            <div v-else-if="partnerData" class="row p-3">
                <div class="col-sm-12" v-html="partnerData.product_description"></div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: ['partner'],
    data() {
        return {
            loading: false,
            partnerData: null,
        }
    },
    mounted() {
        
    },
    methods: {
        getData() {
            this.partnerData = this.partner;
        },
    }
}
</script>

<style lang="scss">
#modal-partner-info {
    .product-partners-list {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);

        .product-partner-item {
            display: flex;
            align-items: center;
            cursor: pointer;

            &__logo {
                margin-right: 0.8em;

                img {
                    width: 40px;
                }
            }
        }
    }
}
</style>